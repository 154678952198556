<template>
  <div>
    <v-layout row wrap class="mb-10 d-flex justify-center">
      <v-flex
        xs12
        md5
        class="elevation-10 text-center white mb-10"
        style="border-radius: 30px; padding: 50px"
      >
        <h1 style="font-size: 38px; font-weight: 700">
          New <span class="underline-text">User</span>
        </h1>
        <p class="mb-10 mt-5">Please enter your email & password</p>
        <v-layout row wrap class="pl-10 pr-10">
          <v-flex xs12 md6 class="pa-3">
            <v-text-field
              single-line
              outlined
              label="First Name"
              hide-details
              style="border-radius: 8px"
            ></v-text-field>
          </v-flex>
          <v-flex xs12 md6 class="pa-3">
            <v-text-field
              single-line
              outlined
              label="Last Name"
              hide-details
              style="border-radius: 8px"
            ></v-text-field>
          </v-flex>
          <v-flex xs12 md6 class="pa-3">
            <v-text-field
              single-line
              outlined
              label="Email"
              hide-details
              style="border-radius: 8px"
            ></v-text-field>
          </v-flex>
          <v-flex xs12 md6 class="pa-3">
            <v-text-field
              single-line
              outlined
              label="Phone Number"
              hide-details
              style="border-radius: 8px"
            ></v-text-field>
          </v-flex>
          <v-flex xs12 md6 class="pa-3">
            <v-text-field
              single-line
              outlined
              label="Password"
              hide-details
              style="border-radius: 8px"
            ></v-text-field>
          </v-flex>
          <v-flex xs12 md6 class="pa-3">
            <v-text-field
              single-line
              outlined
              label="Confirm Password"
              hide-details
              style="border-radius: 8px"
            ></v-text-field>
          </v-flex>
        </v-layout>
       
        <v-btn large rounded class="teal white--text pl-10 pr-10 mt-5"
          >Sign Up</v-btn
        >

        <div class="d-flex align-center justify-space-around mt-10">
          <div>
            <img src="@/assets/divider.svg" style="max-width: 100%" />
          </div>
          <p class="ma-0">Or continue with</p>
          <div>
            <img src="@/assets/divider.svg" style="max-width: 100%" />
          </div>
        </div>
        <div>
          <v-btn
            text
            class="pa-4 mt-5"
            style="
              border-radius: 7px;
              text-transform: capitalize;
              border: 0.49px solid #8a8a8a;
              background: #fff;
              backdrop-filter: blur(22.049999237060547px);
            "
            ><img
              src="@/assets/google.svg"
              class="mr-3"
              style="width: 28px"
            />Sign in with Google</v-btn
          >
        </div>
        <p class="mt-10 mb-0">
          Have an account ? <span @click="login"><u><b>Login Now</b></u></span>
        </p>
      </v-flex>
    </v-layout>
  </div>
</template>
  
  <style scoped>
.underline-text {
  position: relative;
  display: inline-block;
  color: #4aabab;
}
.underline-text::after {
  content: "";
  position: absolute;
  bottom: -10px;
  left: -15px;
  right: 0;
  stroke: 3px;
  width: 120px;
  height: 30px;
  background-image: url("../../assets/underline-new-user.png");
  /* filter: drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.25)); */
  background-repeat: no-repeat;
  background-size: cover;
}
.v-text-field__details {
  margin-bottom: 0;
}
</style>

<script>
export default {
    methods : {
        login(){this.$router.push('/signin')}
    }
}
</script>